<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listados de Áreas</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              v-on:keyup.enter="hazAlgo"
              variant="primary"
              @keyup.enter="$refs.createOrUpdate.showModal()"
              @click="$refs.createOrUpdate.showModal()"
              >Agregar Nuevo</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(getAreaList) ||
                        (getAreaList.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar áreas"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="table-responsive">
                <b-table
                  hover
                  :items="getAreaList"
                  :fields="columns"
                  :filter="filter"
                  :busy="
                    !Array.isArray(getAreaList) || getAreaList.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  :per-page="perPage"
                  :current-page="currentPage"
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>Sin registros</span>
                    </div>
                  </template>
                  <template v-slot:cell(item)="data">
                    <span>{{ data.item.nro }}</span>
                  </template>
                  <template v-slot:cell(name)="data">
                    <span v-if="!data.item.editable">{{ data.item.name }}</span>
                    <input
                      type="text"
                      v-model="data.item.name"
                      v-else
                      class="form-control"
                    />
                  </template>
                  <template v-slot:cell(action)="data">
                    <div v-if="!data.item.editable">
                      <b-button
                        v-b-tooltip.hover
                        title="Editar"
                        variant=" iq-bg-warning mr-1 mb-1"
                        size="sm"
                        @click="$refs.createOrUpdate.showModal(data.item)"
                        ><i class="ri-ball-pen-fill ml-1"></i
                      ></b-button>
                      <b-button
                        v-b-tooltip.hover
                        title="Eliminar"
                        variant=" iq-bg-danger mr-1 mb-1"
                        size="sm"
                        @click="removeItem(data.item)"
                        ><i class="ri-delete-bin-line ml-1"></i
                      ></b-button>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-if="totalRows > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modals ref="createOrUpdate" v-on:refresh="getAreas" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters, mapMutations } from "vuex";
import modals from "@/components/core/modals/AreaModals.vue";
import helpers from "@/Utils/helpers";
export default {
  name: "ListArea",
  components: {
    modals,
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    this.isBusy = true;
    await this.listArea();
    this.totalRows = this.getAreaList.length;
    this.onFiltered(this.getAreaList);
    this.isBusy = false;
  },
  mounted() {
    core.index();
  },
  methods: {
    async getAreas() {
      this.isBusy = true;
      await this.listArea();
      this.totalRows = this.getAreaList.length;
      this.onFiltered(this.getAreaList);
      this.isBusy = false;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ...mapActions({
      listArea: "area/listArea",
      destroyArea: "area/destroyArea",
    }),
    async removeItem(item) {
      try {
        if (await helpers.confirmAction()) {
          const res = await this.destroyArea(item.id);
          this.totalRows = this.getAreaList.length;
          core.showSnackbar("success", res);
        }
      } catch (error) {}
    },
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
    }),
  },

  data() {
    return {
      filter: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isBusy: false,
      columns: [
        { label: "Nombre", key: "name", class: "text-center" },
        { label: "Acciones", key: "action", class: "text-center" },
      ],
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Listado de Áreas",
          active: true,
        },
      ],
      boxOne: "",
      boxTwo: "",
    };
  },
};
</script>
